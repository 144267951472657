import {
  Context,
  namedOperations,
  TransactionDirection,
  TransactionStatus,
  TransactionTemplateFragment,
  TransactionTemplateStatus,
  useCancelTransactionMutation,
  useDeactivateTransactionMutation,
  useRefundTransactionMutation,
  useResendAuthorizationRequestMutation,
  UserPermission,
  useSendProofOfIntentMutation,
  useUpdateTransactionTemplateMutation,
  useUserWithOrganizationPermissionsQuery,
} from "@earnnest-e2-frontend/platform-api/src/graphql"
import { Button, Space, Text, Title } from "@mantine/core"
import moment from "moment"
import {
  RiCloseCircleFill,
  RiRefund2Line,
  RiMailUnreadFill,
  RiMailCheckFill,
} from "react-icons/ri"
import ReceiptResenderForm from "./ReceiptResenderForm"
import { openConfirmModal } from "@mantine/modals"
import { notifications } from "@mantine/notifications"

export default function ManageTransactionForm({
  transactionTemplate,
  viewerRole,
}: {
  transactionTemplate: TransactionTemplateFragment
  viewerRole?: "superuser" | "admin" | "agent"
}) {
  const transaction = transactionTemplate.transactions?.[0]
  const receivingOrganization =
    transactionTemplate.paymentOccasion?.organization
  const userQuery = useUserWithOrganizationPermissionsQuery({
    variables: {
      organizationPermissionsOrgId: receivingOrganization?.id,
    },
  })
  const user = userQuery.data?.user

  const [
    deactivateTransaction,
    deactivateTransactionState,
  ] = useDeactivateTransactionMutation({
    variables: {
      transactionId: transaction?.id,
    },
    refetchQueries: [namedOperations.Query.TransactionTemplates],
  })

  const [
    reactivateTransaction,
    reactivateTransactionState,
  ] = useUpdateTransactionTemplateMutation({
    variables: {
      transactionTemplateId: transactionTemplate.id,
      active: true,
      status:
        transactionTemplate.status === TransactionTemplateStatus.Canceled
          ? TransactionTemplateStatus.Pending
          : transactionTemplate.status,
    },
    refetchQueries: [namedOperations.Query.TransactionTemplates],
  })

  const [
    resendAuthorizationRequest,
    resendAuthorizationRequestState,
  ] = useResendAuthorizationRequestMutation({
    variables: {
      transactionTemplateId: transactionTemplate.id,
    },
  })

  const [
    cancelTransaction,
    cancelTransactionState,
  ] = useCancelTransactionMutation({
    variables: {
      id: transactionTemplate.id,
    },
    refetchQueries: [namedOperations.Query.TransactionTemplates],
  })

  const [
    refundTransaction,
    refundTransactionState,
  ] = useRefundTransactionMutation({
    variables: {
      transactionId: transaction?.id,
    },
    refetchQueries: [namedOperations.Query.TransactionTemplates],
  })

  const [
    sendProofOfIntent,
    sendProofOfIntentState,
  ] = useSendProofOfIntentMutation({
    variables: {
      transactionTemplateId: transactionTemplate.id,
    },
  })

  let actions: (
    | "cancel"
    | "deactivate"
    | "reactivate"
    | "refund"
    | "resendRequest"
    | "poi"
    | "resendReceipts"
  )[] = []
  if (
    transactionTemplate.active &&
    transactionTemplate.paymentOccasion?.direction ===
      TransactionDirection.Inbound &&
    !transactionTemplate.accessedAt &&
    transactionTemplate.status !== TransactionTemplateStatus.ApprovalPending &&
    (!transaction || transaction.status === TransactionStatus.Pending)
  ) {
    actions.push("cancel")
    actions.push("resendRequest")
  }
  if (
    viewerRole === "superuser" &&
    !actions.includes("cancel") &&
    !transactionTemplate.authorizedAt &&
    (!transaction || transaction.status === TransactionStatus.Pending)
  ) {
    actions.push("cancel")
  }
  if (!transactionTemplate.active && viewerRole === "superuser") {
    actions.push("reactivate")
  }
  if (
    transactionTemplate?.active &&
    transactionTemplate.paymentOccasion?.context === Context.Emd &&
    transaction?.depositedAt &&
    !transactionTemplate?.refunded &&
    viewerRole === "admin" &&
    user?.organizationPermissions?.[0]?.roles?.includes(
      UserPermission.RefundPayment,
    )
  ) {
    actions.push("refund")
  }
  if (
    transactionTemplate.paymentOccasion.context === Context.Emd &&
    viewerRole === "agent"
  ) {
    actions.push("poi")
  }
  if (transaction?.initiatedAt) {
    actions.push("resendReceipts")
  }

  if (actions.length === 0) {
    return <Text color="dimmed">Nothing to manage at the moment.</Text>
  }

  return (
    <>
      {actions.includes("cancel") ? (
        <>
          <Title order={4}>Cancel</Title>
          <Text size="sm" color="dimmed">
            The recipient has not yet accepted this payment request. You can
            cancel the request so they will be unable to make this payment.
          </Text>
          <Space h="sm" />
          <Button
            variant="filled"
            color="red"
            leftIcon={<RiCloseCircleFill />}
            loading={cancelTransactionState.loading}
            onClick={() => {
              cancelTransaction()
              try {
                cancelTransaction()
                notifications.show({
                  color: "green",
                  title: "Success",
                  message: "The request has been canceled.",
                })
              } catch (error) {
                notifications.show({
                  color: "red",
                  title: "Error",
                  message: error.message,
                })
              }
            }}>
            Cancel Request
          </Button>
          <Space h="xl" />
        </>
      ) : null}
      {actions.includes("deactivate") ? (
        <>
          <Button
            color="red"
            variant="filled"
            w="100%"
            loading={deactivateTransactionState.loading}
            onClick={() => {
              openConfirmModal({
                title: "Please Confirm",
                children: (
                  <Text size="sm">
                    Are you sure you want to deactivate this transaction?
                  </Text>
                ),
                labels: { confirm: "Confirm", cancel: "Cancel" },
                onConfirm: () => deactivateTransaction(),
              })
            }}>
            Deactivate transaction
          </Button>
          <Text size="sm" color="dimmed">
            This will deactivate the transaction.
          </Text>
          <Space h="xl" />
        </>
      ) : null}
      {actions.includes("reactivate") ? (
        <>
          <Button
            color="red"
            variant="filled"
            w="100%"
            loading={reactivateTransactionState.loading}
            onClick={() => {
              openConfirmModal({
                title: "Please Confirm",
                children: (
                  <Text size="sm">
                    Are you sure you want to reactivate this transaction?
                  </Text>
                ),
                labels: { confirm: "Confirm", cancel: "Cancel" },
                onConfirm: () => reactivateTransaction(),
              })
            }}>
            Reactivate transaction
          </Button>
          <Text size="sm" color="dimmed">
            This will reactivate the transaction. If the transaction's status is
            canceled, it will become a pending transaction
          </Text>
          <Space h="xl" />
        </>
      ) : null}
      {actions.includes("refund") ? (
        <>
          <Button
            variant="filled"
            w="100%"
            leftIcon={<RiRefund2Line />}
            loading={refundTransactionState.loading}
            onClick={async () => {
              try {
                await refundTransaction()
                notifications.show({
                  color: "green",
                  title: "Success",
                  message: "The refund has been initiated.",
                })
              } catch (error) {
                notifications.show({
                  color: "red",
                  title: "Error",
                  message: error.message,
                })
              }
            }}>
            Refund payment
          </Button>
          <Text size="sm" color="dimmed">
            Payment was deposited on{" "}
            {moment(transaction?.depositedAt).format("MMM D, h:mm A")}. If you
            refund this payment it will take 1-3 days to appear in the
            recipient’s account.
          </Text>
          <Space h="xl" />
        </>
      ) : null}
      {actions.includes("resendRequest") ? (
        <>
          <Title order={4}>Resend</Title>
          <Text size="sm" color="dimmed">
            Earnnest sent an email notification to request this payment. You can
            resend this email notification to the recipient.
          </Text>
          <Space h="sm" />
          <Button
            variant="white"
            leftIcon={<RiMailUnreadFill />}
            loading={resendAuthorizationRequestState.loading}
            onClick={() => {
              try {
                resendAuthorizationRequest()
                notifications.show({
                  color: "green",
                  title: "Success",
                  message: "An email notification has been sent.",
                })
              } catch (error) {
                notifications.show({
                  color: "red",
                  title: "Error",
                  message: error.message,
                })
              }
            }}>
            Resend Request
          </Button>
          <Space h="xl" />
        </>
      ) : null}
      {actions.includes("poi") ? (
        <>
          <Title order={4}>Proof of Intent</Title>
          <Text size="sm" color="dimmed">
            This Proof of Intent™ can be used to make offers on the property.
          </Text>
          <Space h="sm" />
          <Button
            variant="filled"
            leftIcon={<RiMailCheckFill />}
            loading={sendProofOfIntentState.loading}
            onClick={() => {
              sendProofOfIntent()
              try {
                sendProofOfIntent()
                notifications.show({
                  color: "green",
                  title: "Success",
                  message: "Proof of intent has been sent.",
                })
              } catch (error) {
                notifications.show({
                  color: "red",
                  title: "Error",
                  message: error.message,
                })
              }
            }}>
            Email me Proof of Intent
          </Button>
        </>
      ) : null}
      {actions.includes("resendReceipts") ? (
        <ReceiptResenderForm transactionTemplate={transactionTemplate} />
      ) : null}
    </>
  )
}
