import { PanelFooter } from "@earnnest-e2-frontend/platform-ui/src/mantine/Panel"
import {
  Button,
  Checkbox,
  Radio,
  Space,
  Stack,
  Text,
  Title,
} from "@mantine/core"
import { useForm } from "@mantine/form"
import { useLocalStorage } from "@mantine/hooks"
import { useEffect } from "react"

type AgentRole = "buyer" | "listing"

export default function StartEMDRequestForm({
  onSubmit,
}: {
  onSubmit: (agentRole: AgentRole) => void
}) {
  const [defaultAgentRole, setDefaultAgentRole] = useLocalStorage<AgentRole>({
    key: "agent-default-role",
    defaultValue: undefined,
  })

  useEffect(() => {
    if (defaultAgentRole) {
      onSubmit(defaultAgentRole)
    }
  }, [defaultAgentRole]) // eslint-disable-line react-hooks/exhaustive-deps

  const form = useForm({
    initialValues: {
      agentRole: "buyer" as "buyer" | "listing",
      setRoleAsDefault: false,
    },
  })

  return (
    <form
      onSubmit={form.onSubmit((values) => {
        if (values.setRoleAsDefault) {
          setDefaultAgentRole(values.agentRole)
        }
        onSubmit(values.agentRole)
      })}>
      <Title size="h2" order={2}>
        Request Payment
      </Title>
      <Space h="xl" />
      <Space h="md" />
      <Title order={5}>
        Do you have the email address of the person that is paying?
      </Title>
      <Space h="xl" />
      <Radio.Group size="lg" {...form.getInputProps("agentRole")}>
        <Stack spacing="lg">
          <Radio
            value="buyer"
            label={
              <Stack spacing={0}>
                <Title order={5}>Yes, I have their email</Title>
                <Text>
                  We’ll ask for it in a bit and use it to send the payment
                  request.
                </Text>
              </Stack>
            }
          />
          <Radio
            value="listing"
            label={
              <Stack spacing={0}>
                <Title order={5}>No, I don’t have their email</Title>
                <Text size="sm">
                  You can invite the person that does have the payer’s email
                  (often the buyer’s agent) to complete the payment request.
                </Text>
              </Stack>
            }
          />
        </Stack>
      </Radio.Group>
      <PanelFooter>
        <Stack align="center">
          <Checkbox
            {...form.getInputProps("setRoleAsDefault")}
            label="Set as default answer"
          />
          <Button type="submit" size="lg" fullWidth>
            Continue
          </Button>
        </Stack>
      </PanelFooter>
    </form>
  )
}
