import { useUserQuery } from "@earnnest-e2-frontend/platform-api/src/graphql"
import LoadingOverlay from "@earnnest-e2-frontend/platform-ui/src/LoadingOverlay"
import { PanelFooter } from "@earnnest-e2-frontend/platform-ui/src/mantine/Panel"
import {
  Anchor,
  Button,
  Group,
  Image,
  Stack,
  Text,
  Textarea,
  ThemeIcon,
  Title,
} from "@mantine/core"
import { useForm, yupResolver } from "@mantine/form"
import { notifications } from "@mantine/notifications"
import React, { useState } from "react"
import { FaMoneyCheck } from "react-icons/fa"
import {
  RiBookMarkFill,
  RiLifebuoyFill,
  RiMailOpenFill,
  RiPhoneFill,
} from "react-icons/ri"
import * as yup from "yup"

export default function SupportForm({
  context,
  extraParams = {},
  viewerRole,
  onSubmitSuccess,
}: {
  context?: string
  extraParams?: object
  viewerRole?: "superuser" | "admin" | "agent"
  onSubmitSuccess?: () => void
}) {
  const userQuery = useUserQuery()
  const user = userQuery.data?.user

  const [creatingTicket, setCreatingTicket] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const form = useForm({
    initialValues: {
      message: "",
      context: context || "",
    },
    validate: yupResolver(
      yup.object().shape({
        message: yup.string().required("Required"),
        context: yup.string(),
      }),
    ),
  })

  if (!user) {
    return <LoadingOverlay />
  }

  if (!creatingTicket) {
    return (
      <Stack spacing="xl">
        <Title size="h2" order={2}>
          Earnnest Support
        </Title>
        <Group align="start" noWrap>
          <ThemeIcon variant="light" color="green">
            <RiPhoneFill size={24} />
          </ThemeIcon>
          <Stack spacing={4}>
            <Title size="h4" order={4}>
              Give us a call
            </Title>
            <Anchor href="tel:18888702336" color="green.5">
              1 (888) 870-2336
            </Anchor>
            <Text size="sm" color="dimmed">
              If your call is not answered immediately, our support team will
              call back within the same business day (usually in under 30
              minutes.)
            </Text>
          </Stack>
        </Group>
        <Group align="start" noWrap>
          <ThemeIcon variant="light" color="green">
            <RiMailOpenFill size={24} />
          </ThemeIcon>
          <Stack spacing={4}>
            <Title size="h4" order={4}>
              Send us an email
            </Title>
            <Anchor href="mailto:support@earnnest.com" color="green.5">
              support@earnnest.com
            </Anchor>
            <Text size="sm" color="dimmed">
              For more detailed questions send us an email. You can expect to
              receive a response in one to two business days.
            </Text>
          </Stack>
        </Group>
        <Group align="start" noWrap>
          <ThemeIcon variant="light" color="green">
            <RiLifebuoyFill size={24} />
          </ThemeIcon>
          <Stack spacing={4}>
            <Title size="h4" order={4}>
              Submit a ticket
            </Title>
            <Anchor color="green.5" onClick={() => setCreatingTicket(true)}>
              Submit a ticket
            </Anchor>
            <Text size="sm" color="dimmed">
              Send us a support ticket anytime and we’ll get back to you within
              one(1) business day.
            </Text>
          </Stack>
        </Group>
        {viewerRole === "agent" ? (
          <Group align="start" noWrap>
            <ThemeIcon variant="light" color="green">
              <RiBookMarkFill size={24} />
            </ThemeIcon>
            <Stack spacing={4}>
              <Title size="h4" order={4}>
                FAQs
              </Title>
              <Anchor
                href="https://earnnest.com/knowledge"
                target="_blank"
                color="green.5">
                Go to knowledge base
              </Anchor>
              <Text size="sm" color="dimmed">
                Find the answers you need to get started using Earnnest today.
              </Text>
            </Stack>
          </Group>
        ) : null}
        {viewerRole === "agent" ? (
          <Group align="start" noWrap>
            <ThemeIcon variant="light" color="green">
              <FaMoneyCheck size={24} />
            </ThemeIcon>
            <Stack spacing={4}>
              <Title size="h4" order={4}>
                Proof of Intent
              </Title>
              <Image
                src="/proof-of-intent--blank.png"
                sx={(theme) => ({
                  border: `1px solid ${theme.fn.themeColor("dark")}`,
                })}
              />
              <Anchor
                href="/proof-of-intent--blank.png"
                color="green.5"
                download={true}>
                Download Proof of Intent
              </Anchor>
              <Text size="sm" color="dimmed">
                This blank Proof of Intent™ can be used to make offers on a
                property.
              </Text>
            </Stack>
          </Group>
        ) : null}
      </Stack>
    )
  }

  return (
    <form
      onSubmit={form.onSubmit(async (values) => {
        try {
          setSubmitting(true)
          const endpoint = process.env.REACT_APP_SF_FORM_ENDPOINT as string
          const response = await fetch(endpoint, {
            method: "post",
            body: JSON.stringify({
              form: "support",
              fields: {
                FirstName: user.firstName,
                LastName: user.lastName,
                Email: user.email,
                Description: values.message,
                ...extraParams,
              },
            }),
          })
          if (response.status !== 200) {
            throw new Error("An unexpected error occurred. Please try again.")
          }
          notifications.show({
            color: "green",
            title: "Success",
            message: "Your support ticket has been sent.",
          })
          onSubmitSuccess?.()
        } catch (error) {
          notifications.show({
            color: "red",
            title: "Error",
            message: error.message,
          })
        } finally {
          setSubmitting(true)
        }
      })}>
      <Stack>
        <Title size="h2" order={2}>
          Create a Support Ticket
        </Title>
        <Textarea
          name="message"
          label="Message"
          placeholder="Tell us how we can help."
          minRows={12}
          {...form.getInputProps("message")}
        />
        <PanelFooter>
          <Button size="lg" type="submit" loading={submitting}>
            {submitting ? "Submitting..." : "Submit"}
          </Button>
        </PanelFooter>
      </Stack>
    </form>
  )
}
