import {
  ActionIcon,
  Button,
  Group,
  Input,
  Select,
  TextInput,
} from "@mantine/core"
import MaskedInput from "react-text-mask"
import { RiCloseCircleFill, RiFilterFill, RiRefreshLine } from "react-icons/ri"
import { useHistory, useLocation } from "react-router-dom"
import { DateInput } from "@mantine/dates"

interface InlineFilterListProps {
  filters: {
    [key: string]: IDFilter | TextFilter | SelectFilter | DateFilter
  }
  queryLoading?: boolean
  refetchQuery?: () => any
}

type IDFilter = {
  type: "id"
  label: string
}

type TextFilter = {
  type: "text"
  label: string
}

type DateFilter = {
  type: "date"
  label: string
}

type SelectFilter = {
  type: "select"
  label: string
  options: {
    value: string
    label: string
  }[]
}

export default function InlineFilterList({
  filters,
  queryLoading,
  refetchQuery,
}: InlineFilterListProps) {
  const location = useLocation()
  const history = useHistory()

  const searchParams = new URLSearchParams(location.search)

  const activeParams = Array.from(searchParams.keys()).filter((key) =>
    filters.hasOwnProperty(key),
  )
  const inactiveParams = Object.keys(filters).filter(
    (key) => !searchParams.has(key),
  )

  return (
    <Group spacing="sm" align="center">
      {refetchQuery ? (
        <ActionIcon
          variant="light"
          loading={queryLoading}
          onClick={() => refetchQuery()}>
          <RiRefreshLine />
        </ActionIcon>
      ) : null}
      {activeParams.map((param, i) => {
        const filter = filters[param]
        const deleteButton = (
          <ActionIcon
            variant="transparent"
            onClick={() => {
              searchParams.delete(param)
              history.replace({
                pathname: location.pathname,
                search: searchParams.toString(),
                state: location.state,
              })
            }}>
            <RiCloseCircleFill />
          </ActionIcon>
        )
        return (
          <Group spacing={0} key={i}>
            <Select
              size="xs"
              value={param}
              autoFocus={!searchParams.get(param)}
              data={Object.entries(filters).map(([key, filter]) => ({
                value: key,
                label: filter.label,
                disabled: activeParams.includes(key),
              }))}
              onChange={(value) => {
                searchParams.delete(param)
                searchParams.set(value, "")
                history.replace({
                  pathname: location.pathname,
                  search: searchParams.toString(),
                  state: location.state,
                })
              }}
            />
            {filter.type === "text" ? (
              <TextInput
                size="xs"
                value={searchParams.get(param)}
                placeholder="Type or paste..."
                onChange={(e) => {
                  searchParams.set(param, e.target.value)
                  history.replace({
                    pathname: location.pathname,
                    search: searchParams.toString(),
                    state: location.state,
                  })
                }}
                rightSection={deleteButton}
              />
            ) : filter.type === "id" ? (
              <Input.Wrapper>
                <Input
                  size="xs"
                  value={searchParams.get(param)}
                  component={MaskedInput}
                  mask={new Array(9).fill(/\d/)}
                  inputMode="numeric"
                  guide={false}
                  placeholder="Enter ID"
                  onChange={(e) => {
                    searchParams.set(param, e.target.value)
                    history.replace({
                      pathname: location.pathname,
                      search: searchParams.toString(),
                      state: location.state,
                    })
                  }}
                  rightSection={deleteButton}
                />
              </Input.Wrapper>
            ) : filter.type === "select" ? (
              <Select
                size="xs"
                value={searchParams.get(param)}
                placeholder={
                  filter.options?.length ? "Select..." : "Loading..."
                }
                data={filter.options || []}
                onChange={(value) => {
                  searchParams.set(param, value)
                  history.replace({
                    pathname: location.pathname,
                    search: searchParams.toString(),
                    state: location.state,
                  })
                }}
                rightSection={deleteButton}
              />
            ) : filter.type === "date" ? (
              <DateInput
                size="xs"
                value={
                  searchParams.get(param)
                    ? new Date(searchParams.get(param))
                    : undefined
                }
                placeholder="Choose date..."
                onChange={(date) => {
                  searchParams.set(param, date.toISOString())
                  history.replace({
                    pathname: location.pathname,
                    search: searchParams.toString(),
                    state: location.state,
                  })
                }}
                rightSection={deleteButton}
              />
            ) : null}
          </Group>
        )
      })}
      {inactiveParams.length ? (
        <Button
          leftIcon={<RiFilterFill />}
          size="sm"
          variant="white"
          onClick={() => {
            searchParams.set(inactiveParams[0], "")
            history.replace({
              pathname: location.pathname,
              search: searchParams.toString(),
              state: location.state,
            })
          }}>
          Add a filter
        </Button>
      ) : null}
    </Group>
  )
}
