import {
  UserPermission,
  useUserQuery,
} from "@earnnest-e2-frontend/platform-api/src/graphql"
import { useEarnnestAuth0 } from "@earnnest-e2-frontend/platform-api/src/providers/EarnnestAuth0"
import {
  LogoutIcon,
  SupportIcon,
} from "@earnnest-e2-frontend/platform-ui/src/Icons"
import LoadingOverlay from "@earnnest-e2-frontend/platform-ui/src/LoadingOverlay/LoadingOverlay"
import {
  DashboardLayout,
  Panel,
} from "@earnnest-e2-frontend/platform-ui/src/mantine"
import AgentOnboardingCard from "@earnnest-e2-frontend/platform-views/src/mantine/AgentOnboardingCard"
import CreateEMDPartialRequestForm from "@earnnest-e2-frontend/platform-views/src/mantine/CreateEMDPartialRequestForm"
import CreateEMDRequestForm from "@earnnest-e2-frontend/platform-views/src/mantine/CreateEMDRequestForm"
import InviteEscrowHolderForm from "@earnnest-e2-frontend/platform-views/src/mantine/InviteEscrowHolderForm"
import StartEMDRequestForm from "@earnnest-e2-frontend/platform-views/src/mantine/StartEMDRequestForm"
import SupportForm from "@earnnest-e2-frontend/platform-views/src/mantine/SupportForm"
import { Button, Group, Image, MediaQuery, Title } from "@mantine/core"
import {
  RiArrowLeftDownLine,
  RiArrowLeftRightFill,
  RiBankFill,
  RiUser3Fill,
} from "react-icons/ri"
import {
  Link,
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from "react-router-dom"
import CallbackPage from "./pages/CallbackPage"
import ErrorPage from "./pages/ErrorPage"
import EscrowHoldersPage from "./pages/EscrowHoldersPage"
import EscrowPanel from "./pages/EscrowPanel"
import LoginPage from "./pages/LoginPage"
import LogoutPage from "./pages/LogoutPage"
import RequestsPage from "./pages/RequestsPage"
import RolePage from "./pages/RolePage"
import TransactionPanel from "./pages/TransactionPanel"
import UserProfilePage from "./pages/UserProfilePage"

export default function App() {
  const history = useHistory()
  const location = useLocation()
  const background = location.state?.background
  const { isLoading, logout } = useEarnnestAuth0()

  const userQuery = useUserQuery()
  const user = userQuery.data?.user

  if (isLoading || userQuery.loading) {
    return <LoadingOverlay />
  }

  if (user) {
    const hasAgentRole =
      user?.roles?.includes(UserPermission.RequestEscrow) ||
      user?.roles?.includes(UserPermission.Request) // we shouldn't be using this, but causing issues with listing flow
    return (
      <Switch>
        <Route path="/role">
          <RolePage />
        </Route>
        <Route path="*">
          <DashboardLayout
            logo={
              <Link to="/">
                <Image
                  src="/icon.png"
                  width={40}
                  height={40}
                  radius="md"
                  mt={-10}
                />
              </Link>
            }
            greeting={
              user.fullName.length < 20 ? user.fullName : user.firstName
            }
            mainNavItems={[
              hasAgentRole
                ? {
                    to: `/requests`,
                    icon: <RiArrowLeftRightFill width={16} height={16} />,
                    title: "Payment Requests",
                    header: (
                      <Group>
                        <MediaQuery
                          smallerThan="md"
                          styles={{ display: "none" }}>
                          <Title size="h2" order={1}>
                            Payment Requests
                          </Title>
                        </MediaQuery>
                        <Link
                          to={{
                            pathname: `/requests/new`,
                            state: { background: location },
                            search: location.search,
                          }}>
                          <Button
                            leftIcon={<RiArrowLeftDownLine />}
                            variant="white">
                            Request Payment
                          </Button>
                        </Link>
                      </Group>
                    ),
                  }
                : null,
              hasAgentRole
                ? {
                    to: `/escrows`,
                    icon: <RiBankFill />,
                    title: "Escrow Holders",
                    header: (
                      <Group>
                        <MediaQuery
                          smallerThan="md"
                          styles={{ display: "none" }}>
                          <Title size="h2" order={1}>
                            Escrow Holders
                          </Title>
                        </MediaQuery>
                        <Link
                          to={{
                            pathname: `/escrows/invite/`,
                            search: location.search,
                            state: { background: location },
                          }}>
                          <Button variant="white">Invite Escrow Holder</Button>
                        </Link>
                      </Group>
                    ),
                  }
                : null,
              {
                to: `/profile`,
                icon: <RiUser3Fill />,
                title: "My Profile",
                header: (
                  <MediaQuery smallerThan="md" styles={{ display: "none" }}>
                    <Title size="h2" order={1}>
                      My Profile
                    </Title>
                  </MediaQuery>
                ),
              },
            ]}
            subNavItems={[
              {
                to: {
                  pathname: `/support`,
                  state: { background: location },
                },
                icon: <SupportIcon />,
                title: "Support",
              },
              {
                icon: <LogoutIcon />,
                title: "Logout",
                onClick: () => {
                  logout({
                    returnTo: `${
                      window.location.origin
                    }/logout?redirectTo=${encodeURIComponent(
                      window.location.pathname,
                    )}`,
                  })
                },
              },
            ]}>
            <AgentOnboardingCard />
            <Switch location={background || location}>
              {hasAgentRole ? (
                <Route path={["/requests", "/requests/*"]}>
                  <RequestsPage />
                </Route>
              ) : null}
              {hasAgentRole ? (
                <Route path={["/escrows", "/escrows/*"]}>
                  <EscrowHoldersPage />
                </Route>
              ) : null}
              <Route path="/profile">
                <UserProfilePage />
              </Route>
              <Route path="/">
                <Redirect to={hasAgentRole ? "/requests" : "/role"} />
              </Route>
              <Route path="*">
                <ErrorPage
                  errorCode="404"
                  errorMessage="This is not a valid url."
                />
              </Route>
            </Switch>
            {hasAgentRole ? (
              <Route path="/requests/:transactionTemplateId/:agentRole?">
                {({ match }) => {
                  function handleClose() {
                    if (location.background) {
                      history.push(location.background)
                    } else {
                      history.push({
                        pathname: "/requests",
                        search: location.search,
                        state: location.state,
                      })
                    }
                  }
                  return (
                    <Panel
                      position="right"
                      opened={!!match}
                      onBack={
                        match?.params?.agentRole
                          ? () => history.goBack()
                          : undefined
                      }
                      onClose={handleClose}>
                      {match?.params?.transactionTemplateId === "new" ? (
                        match?.params?.agentRole === "buyer" ? (
                          <CreateEMDRequestForm
                            onSubmitSuccess={(transactionTemplate) => {
                              history.push({
                                pathname: `/requests/${transactionTemplate?.id}`,
                                state: location.state,
                                search: location.search,
                              })
                            }}
                          />
                        ) : match?.params?.agentRole === "listing" ? (
                          <CreateEMDPartialRequestForm
                            onSubmitSuccess={handleClose}
                          />
                        ) : (
                          <StartEMDRequestForm
                            onSubmit={(agentRole) => {
                              history.push({
                                pathname: `/requests/new/${agentRole}`,
                                state: location.state,
                                search: location.search,
                              })
                            }}
                          />
                        )
                      ) : (
                        <TransactionPanel />
                      )}
                    </Panel>
                  )
                }}
              </Route>
            ) : null}
            {hasAgentRole ? (
              <Route path="/escrows/:escrowAccountId">
                {({ match }) => (
                  <Panel
                    position="right"
                    opened={!!match}
                    onClose={() => {
                      if (location.background) {
                        history.push(location.background)
                      } else {
                        history.push({
                          pathname: `/escrows`,
                          search: location.search,
                          state: location.state,
                        })
                      }
                    }}>
                    {match?.params?.escrowAccountId === "invite" ? (
                      <InviteEscrowHolderForm
                        name={new URLSearchParams(location.search).get("query")}
                        onSubmitSuccess={() => {
                          if (location.background) {
                            history.push(location.background)
                          } else {
                            history.push({
                              pathname: `/escrows`,
                              search: location.search,
                              state: location.state,
                            })
                          }
                        }}
                      />
                    ) : (
                      <EscrowPanel />
                    )}
                  </Panel>
                )}
              </Route>
            ) : null}
            <Route path="/support">
              {({ match }) => (
                <Panel
                  position="right"
                  opened={!!match}
                  onClose={() => {
                    if (location.background) {
                      history.push(location.background)
                    } else {
                      history.push(`/requests`)
                    }
                  }}>
                  <SupportForm
                    viewerRole="agent"
                    extraParams={{
                      Origin: "EPDashboard",
                      Subject: "T-NA-NA-EPDashboard",
                    }}
                    onSubmitSuccess={() => {
                      if (location.background) {
                        history.push(location.background)
                      } else {
                        history.push(`/requests`)
                      }
                    }}
                  />
                </Panel>
              )}
            </Route>
          </DashboardLayout>
        </Route>
      </Switch>
    )
  } else {
    return (
      <Switch>
        <Route path="/logout">
          <LogoutPage />
        </Route>
        <Route path="/callback">
          <CallbackPage />
        </Route>
        <Route>
          <LoginPage />
        </Route>
      </Switch>
    )
  }
}
