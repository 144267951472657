import { useEarnnestAnalytics } from "@earnnest-e2-frontend/platform-api/src/providers/EarnnestAnalytics"
import { useEarnnestAPI } from "@earnnest-e2-frontend/platform-api/src/providers/EarnnestAPI"
import { useEarnnestAuth0 } from "@earnnest-e2-frontend/platform-api/src/providers/EarnnestAuth0"
import Button from "@earnnest-e2-frontend/platform-ui/src/Button"
import { useImageSizeConstraints } from "@earnnest-e2-frontend/platform-ui/src/Image"
import PoweredBy from "@earnnest-e2-frontend/platform-ui/src/Earnnest/PoweredBy"
import { LockIcon } from "@earnnest-e2-frontend/platform-ui/src/Icons"
import LoadingOverlay from "@earnnest-e2-frontend/platform-ui/src/LoadingOverlay/LoadingOverlay"
import Panel from "@earnnest-e2-frontend/platform-ui/src/Panel/Panel"
import {
  Box,
  Text,
  ThemeProvider,
  useTheme,
} from "@earnnest-e2-frontend/platform-ui/src/UI"
import React, { useCallback, useEffect, useState } from "react"
import { Image } from "react-native"
import { useMediaQuery } from "react-responsive"
import { useLocation, useRouteMatch } from "react-router-dom"
import tinycolor from "tinycolor2"

type OrgPublicData = {
  name: string
  logoDark: string
  logoLight: string
  backgroundColor: string
}

export default function LoginPage() {
  const routeMatch = useRouteMatch("/:orgSlug")

  const orgSlug = routeMatch?.params.orgSlug

  const isMobile = useMediaQuery({ maxWidth: 1000 })
  const location = useLocation()
  const { getColor } = useTheme()
  const { getAccessTokenSilently, loginWithRedirect } = useEarnnestAuth0()
  const { publicAPI } = useEarnnestAPI()
  const { track } = useEarnnestAnalytics()

  const [orgLoading, setOrgLoading] = useState<boolean>(false)
  const [, setOrgError] = useState<Error | null>(null)
  const [orgData, setOrgData] = useState<OrgPublicData | null>(null)

  const {
    width: logoWidth,
    height: logoHeight,
    ready: logoReady,
  } = useImageSizeConstraints(orgData?.logoLight, 300)

  const loadOrg = useCallback(
    async (orgSlug: string) => {
      try {
        setOrgError(null)
        setOrgLoading(true)
        const result = await publicAPI.get("/organizations/" + orgSlug)
        const json = await result.json()
        setOrgData(json)
      } catch (error) {
        setOrgError(new Error(error))
      } finally {
        setOrgLoading(false)
      }
    },
    [publicAPI],
  )

  useEffect(() => {
    if (orgSlug) {
      loadOrg(orgSlug)
    }
  }, [orgSlug, loadOrg])

  useEffect(() => {
    getAccessTokenSilently().catch((error) => {
      console.error("Attempt to silently get access token failed", error)
    })
  }, [getAccessTokenSilently])

  useEffect(() => {
    getAccessTokenSilently().catch((error) => {
      console.error("Attempt to silently get access token failed", error)
    })
  }, [getAccessTokenSilently])

  if (orgLoading) {
    return <LoadingOverlay />
  }

  const bg = orgData?.backgroundColor || getColor("purple")

  return (
    <>
      <ThemeProvider activeScheme="light">
        <Panel overlay={false}>
          <Box
            style={{
              flex: 1,
              alignItems: "center",
              justifyContent: "center",
            }}>
            <Box style={{ flex: isMobile ? 1 : undefined }} />
            {isMobile ? (
              <Image
                source={{ uri: orgData?.logoDark }}
                resizeMode="contain"
                style={{
                  width: 300,
                  height: 75,
                }}
              />
            ) : null}
            <Text
              type="heading3"
              color="contrast100"
              style={{ textAlign: "center" }}>
              Secure and simple payments
            </Text>
            <Box h={8} />
            <Text
              type="baseText"
              color="contrast100"
              style={{ textAlign: "center" }}>
              New to Earnnest? We’ll guide you through account set up.
            </Text>
            <Box h={48} style={{ flex: isMobile ? 1 : undefined }} />
            <Button
              title="Sign in to continue"
              LeftIcon={LockIcon}
              size="lg"
              style={{ width: 300 }}
              onPress={() => {
                track("Login Attempted")
                const returnTo = location.pathname + location.search
                localStorage.clear()
                localStorage.setItem("loginReturnTo", returnTo)
                loginWithRedirect({
                  appState: { returnTo },
                })
              }}
            />
          </Box>
        </Panel>
      </ThemeProvider>
      <ThemeProvider activeScheme={tinycolor(bg).isDark() ? "dark" : "light"}>
        <Box
          style={{
            flex: 1,
            alignSelf: "stretch",
            alignItems: "center",
            backgroundColor: bg,
            transform: [{ translateX: 300 }],
          }}>
          <Box
            style={{
              flex: 1,
              maxWidth: 300,
              alignItems: "center",
              justifyContent: "center",
              opacity: logoReady ? 1 : 0,
            }}>
            {orgData ? (
              <Image
                source={{ uri: orgData?.logoLight }}
                resizeMode="contain"
                style={{
                  width: logoWidth,
                  height: logoHeight,
                }}
              />
            ) : null}
            {orgData ? (
              <Text
                type="baseText"
                color="contrast100"
                style={{ textAlign: "center", marginTop: 4 }}>
                {orgData.name} uses Earnnest for secure digital real estate
                payments.
              </Text>
            ) : null}
          </Box>
          <Box
            py={32}
            style={{
              alignItems: "center",
              justifyContent: "center",
            }}>
            <PoweredBy color="contrast100" />
          </Box>
        </Box>
      </ThemeProvider>
    </>
  )
}
